<template>
  <!-- 上传文件 -->
  <a-modal
    :title="$t('upload.upload_files')"
    :width="548"
    :centered="true"
    :maskClosable="false"
    :visible="visible"
    @ok="upOk"
    @cancel="cancel"
  >
    <a-spin :spinning="loading" :delay="500">
      <secret
        :value="secret"
        :isForm="false"
        :options="secretOptions"
        @secret:change="(e) => (secret = e)"
      />
      <div class="file" :class="fileList.length >= config.limit && 'upbtn-hid'">
        <a-upload-dragger
          v-model:fileList="fileList"
          :multiple="config.limit > 1"
          :showUploadList="false"
          name="file"
          :data="{ secret }"
          :action="uploadUrl + config.action"
          :headers="getHeader()"
          :accept="config.accept"
          :beforeUpload="handleBeforeUpload"
          @change="handleUploadChange"
          @reject="handleUploadReject"
          :disabled="isHtsySecret"
        >
          <p class="ant-upload-drag-icon">
            <inbox-outlined></inbox-outlined>
            <!-- <inbox-outlined style="font-size: 80px" /> -->
          </p>
          <p class="ant-upload-hint">
            <span v-if="isHtsySecret">请先选择密级，再</span>
            {{ $t('upload.drop_tip') }}
            <!-- 点击或直接将文件拖拽到此处上传。 -->
          </p>
          <p class="ant-upload-hint" v-if="config.accept">{{ $t('upload.supported_file_types') }}：{{ config.accept }}</p>
          <!-- 支持文件类型： -->
        </a-upload-dragger>
        <ul class="upload-list" v-show="fileList.length">
          <li class="file-item" v-for="(file, i) in fileList" :key="i">
            <PaperClipOutlined />
            <span class="file-item-name">
              <template v-if="file.secretData">
                {{ htsySecret[file.secretData] }}
              </template>
              {{ file.name }}
            </span>
            <DeleteOutlined class="del" @click="removeFile(i)" />
          </li>
        </ul>
      </div>
    </a-spin>
  </a-modal>
</template>
<script>
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  computed,
} from "vue";
import { uploadUrl, getHeader } from "@/utils/tools";
import secret from "@/components/secret.vue";
import { useStore } from "vuex";
import { InboxOutlined } from "@ant-design/icons-vue";
import { fileCheck } from "@/api/other";
import BMF from "browser-md5-file";
import { htsySecret } from "@/utils/business";

export default defineComponent({
  name: "secretFile", // 上传密级附件
  components: {
    secret,
    InboxOutlined,
  },
  props: {
    secretOptions: {
      type: Array,
      default: () => {
        return [
          { label: "非密", value: 50 },
          { label: "内部", value: 60 },
          { label: "秘密", value: 70 },
          { label: "机密", value: 80 },
          // { label: "绝密", value: 90 },
        ];
      },
    },
    config: {
      type: Object,
      default: () => {
        return {
          action: "study/upload/file",
          accept: "", // .xls,.xlsx
          maxSize: 10, // M
          limit: 1,
          sizeTip: "",
        };
      },
    },
  },
  emits: ["ok"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      loading: false,
      secret: 0,
      fileList: [],
    });

    const bmf = new BMF();

    const handleBeforeUpload = (file) => {
      if (!file.size) {
        proxy.$message.error($t('upload.max_size_tip'));
        // 文件大小不能为0
        return false;
      }
      const sizeFlag = file.size < props.config.maxSize * 1024 * 1024;
      if (!sizeFlag) {
        proxy.$message.error(props.config.sizeTip || $t('upload.max_size_tip', [`${props.config.maxSize}MB`]));
        // 文件大小不能超过
        return sizeFlag;
      }
      if (state.fileList.length >= props.config.limit) {
        proxy.$message.error($t('upload.max_count_tip', [props.config.limit]));
        // 最多上传{0}个文件
        return false;
      }
      file.secretData = state.secret;
      // return new Promise((resolve, reject) => {
      //   bmf.md5(file, (err, md5) => {
      //     return fileCheck(md5).then(res => {
      //       if (res.ret == 0 && res.data.exist == false) {
      //         return resolve(file);
      //       } else {
      //         res.data.file = res.data.md5;
      //         res.data.name = file.name.substring(0, file.name.lastIndexOf("."));
      //         res.data.suffix = file.name.substring(file.name.lastIndexOf(".")+1);
      //         // if (store.getters['user/platformConfig'].htsySecret == 1) {
      //         //   res.data.name = htsySecret[state.secret] + '-' + res.data.name;
      //         // }
      //         // emit('ok', {
      //         //   secret: state.secret,
      //         //   file: res.data,
      //         // });
      //         // state.visible = false;
      //         let response = { data: res.data };
      //         file.response = response;
      //         file.percent = 100;
      //         file.status = "done";
      //         state.fileList.push(file);
      //         return false;
      //       }
      //     });
      //   });
      // });
    };

    const handleUploadChange = info => {
      if (info.file.status === "uploading") {
        state.loading = true;
        if (!state.visible) {
          info.file.status = "error";
          state.loading = false;
        }
        return;
      }
      if (info.file.status === "done") {
        let res = info.file.response;
        if (res.ret == 0) {
          // if (store.getters.platformConfig.htsySecret == 1) {
          //   res.data.name = htsySecret[state.secret] + "-" + res.data.name;
          // }
          res.data.secretData = state.secret;
          // if (res.data.exist) {
          //   res.data.file = res.data.md5;
          // }
          // emit('ok', {
          //   secret: state.secret,
          //   file: res.data,
          // });
          // state.visible = false;
        } else {
          state.fileList.forEach((item, i) => {
            if (item.uid === info.file.uid) {
              removeFile(i);
            }
          });
          proxy.$message.error(res.msg);
        }
        state.loading = false;
      }
      if (info.file.status === "error") {
        state.fileList.forEach((item, i) => {
          if (item.uid === info.file.uid) {
            removeFile(i);
          }
        });
        state.loading = false;
        proxy.$message.error($t('upload.upload_error'));
        // 上传错误
      }
    };

    const handleUploadReject = fileList => {
      proxy.$message.error($t('upload.the_file_format_is_not_supported'));
      // 文件格式不支持
    };

    const removeFile = (i) => {
      state.fileList.splice(i, 1);
    };

    const show = () => {
      state.fileList = [];
      state.secret = 0;
      state.visible = true;
    };

    const upOk = () => {
      if (!state.fileList.length) {
        return proxy.$message.error($t('upload.please_upload_the_file'));
        // 请上传文件
      }
      emit("ok", {
        secret: state.secret,
        file: state.fileList,
      });
      state.visible = false;
    };

    const cancel = () => {
      state.loading = false;
      state.visible = false;
    };

    const store = useStore();
    // 平台配置是否开启密级
    const isHtsySecret = computed(() => {
      const flag = store.getters.platformConfig.htsySecret == 1 && state.secret <= 0;
      return flag;
    });

    return {
      getHeader,
      uploadUrl,
      ...toRefs(state),
      handleBeforeUpload,
      handleUploadChange,
      handleUploadReject,
      show,
      cancel,
      isHtsySecret,
      upOk,
      htsySecret,
      removeFile,
    };
  },
});
</script>
<style lang="less" scoped>
.file {
  margin-top: 24px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 10px;
}
.upload-list {
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f5f5f5;
    &-name {
      flex: 1;
      margin: 0 10px;
    }
  }
}
.upbtn-hid :deep .ant-upload-btn {
  display: none;
}
</style>
